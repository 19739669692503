import api from "@/base/utils/request";

//获取其他设置
export const getCycOther = data => {
    return api({
        url: "/admin/cyc_circle/Settings/getCycCircleShareConfig",
        method: "post",
        data
    });
};
//保存其他设置
export const setCycOther = data => {
    return api({
        url: "/admin/cyc_circle/Settings/setCycCircleShareConfig",
        method: "post",
        data
    });
};
<template>
  <div class="content" v-loading="showLoad">
    <div class="share-config">
      <div class="config-section-title">小程序分享配置</div>
      <el-form ref="shareForm" class="small-form" :model="shareForm" :rules="rules" label-width="130px"
        size="medium">
        <el-form-item label="分享标题：" prop="share_title">
          <el-input v-model="shareForm.share_title" placeholder="请填写分享标题："></el-input>
        </el-form-item>
        <el-form-item label="请上传分享图片：" prop="share_image">
          <SingleMediaWall ratio="500:400" v-model="shareForm.share_image" fit="contain" :width="104" :height="104"
            :borderRadius="4">
            <p class="info" slot="info">建议上传尺寸比例为500*400</p>
          </SingleMediaWall>
          <el-input type="text" v-model="shareForm.share_image" v-show="false"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <FixedActionBar>
      <el-button @click="handleSaveConfig('shareForm')" type="primary" :loading="saveLoading">保存</el-button>
    </FixedActionBar>
  </div>
</template>

<script>
import { getCycOther, setCycOther } from "../api/other-config";
import SingleMediaWall from "../../common/components/SingleMediaWall";
import FixedActionBar from "../../../base/layout/FixedActionBar";
import regs from "../../../base/utils/regs";
export default {
  name: "other-install",
  data() {
    return {
      shareForm: {
        share_title: "",
        share_image: "",
      },
      showLoad: false,
      saveLoading: false,
      rules: {
        share_title: [
          { required: true, message: "请输入标题", trigger: "blur" },
        ],
        share_image: [
          {
            validator: regs.checkImg(this, "share_image", {
              formName: "shareForm",
            }),
          },
          { required: true, message: "请选择图片" },
        ],
      },
    };
  },
  components: {
    FixedActionBar,
    SingleMediaWall,
  },
  created() {
    this.showLoad = true;
    this.getCycOtherConfig();
  },
  methods: {
    handleSaveConfig(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          let data = {
            share_title: this.shareForm.share_title,
            share_image: this.shareForm.share_image,
          };
          setCycOther({ config: data })
            .then((res) => {
              this.$message({
                message: "保存成功",
                type: "success",
              });
            })
            .catch((err) => {})
            .finally(() => {
              this.saveLoading = false;
            });
        }
      });
    },
    getCycOtherConfig() {
      getCycOther()
        .then((res) => {
          this.shareForm.share_title = res.data.share_title;
          this.shareForm.share_image = res.data.share_image;
        })
        .catch((err) => {})
        .finally(() => {
          this.showLoad = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.share-config {
  display: flex;
  flex-direction: column;
  .share-top {
    padding: 7px 4px 19px;
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    border-bottom: 1px solid #ebeef5;
  }
  .avatar-uploader {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 104px;
    height: 104px;
    .avatar-uploader :hover {
      border-color: #409eff;
    }
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 104px;
    height: 104px;
    line-height: 100px;
    text-align: center;
  }
  .avatar {
    width: 104px;
    height: 104px;
    display: block;
  }
}
.bottom-config {
  margin-top: 50px;
  .bottom-title {
    font-size: 20px;
    color: rgb(50, 73, 101);
    margin-bottom: 22px;
  }
  .phone-status-none {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin: 5px;
    background-color: rgb(144, 147, 153);
  }
  .phone-status-has {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin: 5px;
    background-color: rgb(19, 206, 102);
  }
  .add-bottom {
    margin-bottom: 50px;
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px dashed rgba(217, 217, 217, 1);
    font-size: 14px;
    color: rgba(70, 128, 255, 1);
    cursor: pointer;
    &:hover {
      border: 1px dashed rgba(70, 128, 255, 1);
    }
  }
}
</style>
